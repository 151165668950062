import React from "react";
import NepalMap from "../../components/NepalMap";

export default function Map() {
  return (
    <div>
      <NepalMap />
    </div>
  );
}
